import { createContext, useContext, useState, useEffect } from 'react';
import {
  CREATE_MANUAL_PAYMENT_METHOD,
  DELETE_PAYMENT_GATEWAY,
  DIRECT_STATS_URL,
  DIRECT_TRANSACTIONS_URL,
  DUKAAN_DELIVERY_STORE_WALLET,
  DUKAAN_DELIVERY_TRANSACTIONS,
  EXPORT_PAYMENT_DATA,
  FETCH_STRIPE_KEY,
  GENRATE_KYC_OTP_URL,
  GET_PAYMENT_GATEWAY_CREDS,
  TRANSACTIONS_URL,
  UPDATE_MANUAL_PAYMENT_METHOD,
  PAYMENT_PROVIDER_DISCOUNT,
  VERIFY_KYC_OTP_URL,
  WALLET_URL,
  GET_CASHFREE_ACCOUNT_STATUS,
  CREATE_CASHFREE_MERCHANT_ACCOUNT,
  GET_CASHFREE_ONBOARDING_LINK,
} from '../ApiUrls';
import { noop } from '../utils';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from '../utils/http';
import { useAppContext } from './AppContext';
import { useCountry } from './CountryProvider';
import { useSubscription } from './SubscriptionProvider';

const PaymentsContext = createContext({
  submitting: false,
  loading: false,
  isDukaanPaySetup: false,
  transactionsUrl: '',
  statsUrl: '',
  dukaanDeliveryWalletUrl: '',
  dukaanDeliveryTransactions: '',
  exportPaymentData: '',
  manualPaymentProviders: [],
  paymentProvider: null,
  paymentProviderData: {},
  fetchStripeKeys: noop,
  sendAadharKYCOTP: noop,
  verifyAadharKYCOTP: noop,
  setSubmitting: noop,
  addManualPaymentMethod: noop,
  updateManualPaymentMethod: noop,
  handleDeletePaymentGateway: noop,
  fetchPaymentGatewayCreds: noop,
});

export const usePayments = () => useContext(PaymentsContext);

export const STRIPE_KEY = 'stripe';
export const PAYPAL_KEY = 'paypal';
export const RAZORPAY_KEY = 'razor_pay';
export const CASHFREE_KEY = 'cashfree';
export const PHONEPE_KEY = 'phonepe';
export const PADDLE_KEY = 'paddle';
export const MANUAL_TRANSFER_KEY = 'manual_transfer';
export const COD_KEY = 'cod';

export const PAYMENT_KEY_LABELS = {
  [STRIPE_KEY]: 'Stripe',
  [PAYPAL_KEY]: 'PayPal',
  [RAZORPAY_KEY]: 'Razorpay',
  [PHONEPE_KEY]: 'PhonePe',
  [CASHFREE_KEY]: 'Cashfree',
  [PADDLE_KEY]: 'Paddle',
  [MANUAL_TRANSFER_KEY]: 'Manual Transfer',
};

const PaymentsProvider = ({ children }) => {
  const {
    business,
    constants,
    fetchBusinessDetails: refetchBusiness,
    paymentKeys = [],
    activePaymentProvider = {},
    isEnterprise,
  } = useAppContext();
  const { isInfinityPlan } = useSubscription();
  const { isInternational } = useCountry();
  const [payoutLimit, setPayoutLimit] = useState(0);
  const [loading, setLoading] = useState(false);

  const [paymentProviderDiscountEnabled, setPaymentProviderDiscountEnabled] =
    useState(false);

  const [existingPartnerCredentials, setExistingPartnerCredentials] = useState(
    {}
  );
  const [submitting, setSubmitting] = useState(false);
  const {
    payment_provider: paymentProvider,
    payment_provider_data: paymentProviderData,
  } = activePaymentProvider || {};

  const manualPaymentProviders = paymentKeys.filter(
    (item) => item.provider_type === 'manual'
  );

  const stripeKey = paymentKeys?.find((key) => key.provider === STRIPE_KEY);
  const paypalKey = paymentKeys?.find((key) => key.provider === PAYPAL_KEY);
  const razorpayKey = paymentKeys?.find((key) => key.provider === RAZORPAY_KEY);
  const cashfreeKey = paymentKeys?.find((key) => key.provider === CASHFREE_KEY);
  const phonepeKey = paymentKeys?.find((key) => key.provider === PHONEPE_KEY);

  const isIndiaPaymentsSetup =
    razorpayKey || cashfreeKey || Boolean(manualPaymentProviders.length);
  const isIntlPaymentSetup = Boolean(paymentKeys.length);

  const isPaymentsSetup = isInternational
    ? isIntlPaymentSetup
    : isIndiaPaymentsSetup;

  const isOnlinePaymentsSetup = isInternational
    ? Boolean(paymentKeys.filter((item) => item.provider !== COD_KEY).length)
    : razorpayKey || cashfreeKey || phonepeKey;

  const isDukaanPaySetup = !!cashfreeKey;

  const transactionsUrl = (storeUuid) =>
    isInternational || paymentProvider === RAZORPAY_KEY
      ? DIRECT_TRANSACTIONS_URL
      : TRANSACTIONS_URL(storeUuid);

  const statsUrl = (storeUuid) =>
    isInternational || paymentProvider === RAZORPAY_KEY
      ? DIRECT_STATS_URL
      : WALLET_URL(storeUuid);

  const dukaanDeliveryWalletUrl = DUKAAN_DELIVERY_STORE_WALLET;
  const dukaanDeliveryTransactions = DUKAAN_DELIVERY_TRANSACTIONS;
  const exportPaymentData = EXPORT_PAYMENT_DATA;

  const showKycBanner =
    !isInternational &&
    !isEnterprise &&
    (cashfreeKey || razorpayKey || phonepeKey) &&
    !business?.meta?.kyc_completed;

  const fetchStripeKeys = (successCallback = noop, errorCallback = noop) => {
    setLoading(true);
    getRequest({ url: FETCH_STRIPE_KEY })
      .then(successCallback)
      .catch(errorCallback)
      .finally(() => setLoading(false));
  };

  const sendAadharKYCOTP = (
    aadharNumber,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);

    postRequest({
      url: GENRATE_KYC_OTP_URL,
      data: { aadhaar_number: aadharNumber },
    })
      .then(successCallback)
      .catch(errorCallback);
  };

  const verifyAadharKYCOTP = (
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);

    postRequest({
      url: VERIFY_KYC_OTP_URL,
      data: payload,
    })
      .then(successCallback)
      .catch(errorCallback);
  };

  const handleDeletePaymentGateway = (
    gatewayUuid,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setSubmitting(true);
    deleteRequest({ url: DELETE_PAYMENT_GATEWAY(business.uuid, gatewayUuid) })
      .then(successCallback)
      .catch(errorCallback)
      .finally(() => setSubmitting(false));
  };

  const fetchPaymentGatewayCreds = (
    gatewayUuid,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setLoading(true);
    getRequest({ url: GET_PAYMENT_GATEWAY_CREDS(business.uuid, gatewayUuid) })
      .then(successCallback)
      .catch(errorCallback)
      .finally(() => setLoading(false));
  };

  const getCashfreeAccountStatus = (
    successCallback = noop,
    errorCallback = noop
  ) => {
    setLoading(true);
    getRequest({ url: GET_CASHFREE_ACCOUNT_STATUS(business.uuid) })
      .then(successCallback)
      .catch(errorCallback)
      .finally(() => setLoading(false));
  };

  const getCashfreeOnboardingLink = (
    successCallback = noop,
    errorCallback = noop
  ) => {
    setLoading(true);
    getRequest({ url: GET_CASHFREE_ONBOARDING_LINK(business.uuid) })
      .then(successCallback)
      .catch(errorCallback)
      .finally(() => setLoading(false));
  };

  const createCashFreeMerchantAccount = (
    payload,
    successCallback = noop,
    errorCallback = noop
  ) => {
    setLoading(true);
    postRequest({
      url: CREATE_CASHFREE_MERCHANT_ACCOUNT(business.uuid),
      data: payload,
    })
      .then(successCallback)
      .catch(errorCallback)
      .finally(() => setLoading(false));
  };

  const addManualPaymentMethod = (
    payload,
    successCallback = noop,
    errorCb = noop
  ) => {
    setSubmitting(true);
    postRequest({
      url: CREATE_MANUAL_PAYMENT_METHOD,
      data: payload,
    })
      .then((data) => {
        successCallback(data);
        refetchBusiness();
      })
      .catch((e) => {
        errorCb(e);
      })
      .finally(() => setSubmitting(false));
  };

  const updateManualPaymentMethod = (
    uuid,
    payload,
    successCallback = noop,
    errorCb = noop
  ) => {
    setSubmitting(true);
    patchRequest({
      url: UPDATE_MANUAL_PAYMENT_METHOD(uuid),
      data: payload,
    })
      .then((data) => {
        successCallback(data);
        refetchBusiness();
      })
      .catch((e) => {
        errorCb(e);
      })
      .finally(() => setSubmitting(false));
  };

  const fetchPaymentProviderDiscount = () => {
    setLoading(true);
    getRequest({
      url: PAYMENT_PROVIDER_DISCOUNT,
    })
      .then((data) => {
        setPaymentProviderDiscountEnabled(data?.data?.is_active ?? false);
      })
      .finally(() => setLoading(false));
  };

  const updatePaymentProviderDiscount = (
    discountActive,
    successCallback = noop,
    errorCb = noop
  ) => {
    setSubmitting(true);
    patchRequest({
      url: PAYMENT_PROVIDER_DISCOUNT,
      data: { is_active: discountActive },
    })
      .then((data) => {
        successCallback(data);
      })
      .catch((e) => {
        errorCb(e);
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    setPayoutLimit(constants?.payments?.PAYOUT_LIMIT_BEFORE_KYC);
  }, [constants]);

  const contextValue = {
    loading,
    submitting,
    paymentKeys,
    stripeKey,
    paypalKey,
    razorpayKey,
    cashfreeKey,
    phonepeKey,
    paymentProvider,
    paymentProviderData,
    isPaymentsSetup,
    isOnlinePaymentsSetup,
    showKycBanner,
    payoutLimit,
    existingPartnerCredentials,
    manualPaymentProviders,
    isDukaanPaySetup,
    setExistingPartnerCredentials,
    transactionsUrl,
    statsUrl,
    dukaanDeliveryWalletUrl,
    dukaanDeliveryTransactions,
    exportPaymentData,
    setSubmitting,
    fetchStripeKeys,
    sendAadharKYCOTP,
    verifyAadharKYCOTP,
    addManualPaymentMethod,
    updateManualPaymentMethod,
    refetchBusiness,
    handleDeletePaymentGateway,
    fetchPaymentGatewayCreds,
    getCashfreeAccountStatus,
    createCashFreeMerchantAccount,
    getCashfreeOnboardingLink,
    updatePaymentProviderDiscount,
    paymentProviderDiscountEnabled,
    fetchPaymentProviderDiscount,
  };

  return (
    <PaymentsContext.Provider value={contextValue}>
      {children}
    </PaymentsContext.Provider>
  );
};

export default PaymentsProvider;
