/* eslint-disable no-nested-ternary */
import COLORS from '../../colorConstants';
import { StarIconYellow } from '../../SvgIcon';
import { accountUrls } from '../../Urls';
import RazorpayCtaButton from './RazorpayCtaButton';

const CtaButton = () => <div>Avail Now</div>;

export const HELLOBAR_CONFIG = ({
  isMobile = false,
  isInternational = false,
  discountPercent = 50,
}) => [
  {
    classes: {
      root: {
        width: '100%',
        background: isMobile
          ? 'radial-gradient(circle closest-corner at 50% -210%, rgba(221,49,134,1) 62%, rgba(94,34,169,1) 100%)'
          : '#5E22A9',
        padding: isMobile ? '12px 32px' : '0px',
        justifyContent: 'center',
        position: 'relative',
      },
      gradientDiv: {
        padding: isMobile ? '' : '8px 32px',
        background: isMobile
          ? ''
          : 'radial-gradient(circle closest-corner at 50% 375%, rgba(221,49,134,1) 62%, rgba(94,34,169,1) 95%)',
        height: isMobile ? '' : '100%',
        position: isInternational || isMobile ? 'relative' : 'absolute',
        left: 0,
      },
      icon: {
        '& path': { fill: COLORS.WHITE },
      },
      boldText: {
        color: COLORS.WHITE,
        fontStyle: 'italic',
        fontSize: 18,
        lineHeight: '20px',
        fontWeight: 900,
      },
      discountTextDiv: {
        paddingRight: !isMobile ? 32 : 0,
      },
      button: {
        padding: '6px 12px',
        backgroundColor: COLORS.SEO.TOO_LONG,
        color: '#220158',
      },
      timer: {
        padding: isMobile ? 0 : '8px 32px 8px 0px',
      },
    },
    dealName: 'LIMITED TIME OFFER',
    icon: StarIconYellow,
    showIconOnLeft: true,
    showIconOnRight: true,
    discountText: `Flat ${discountPercent}% off on Gold and Platinum plan`,
    discountTextInternational: `🔥 ${discountPercent}% discount on all plans till 28th November, 2022. Use code: DUKAANCM50`,
    showButtonRedirect: !isMobile,
    buttonText: 'Avail 50% OFF',
    buttonRedirectLink: accountUrls.subscriptionPurchasePath,
    showTimer: true,
    showDealAfterTimerEnd: true,
    startDateInternational: '11/26/2022',
    endDateInternational: '11/29/2022',
    timerEndDateInternational: '11/29/20223',
    startDate: '10/26/2022',
    endDate: '11/29/2022',
    timerEndDate: '11/29/2022',
    subscriptionStripColor: '#5E22A9',
  },
  {
    classes: {
      root: {
        background:
          'radial-gradient(68.13% 61318.26% at 6.21% 50%, #4F038A 0%, #1B0050 100%)',
        padding: isMobile ? '12px 32px' : '8px 32px',
      },
      gradientDiv: {},
      boldText: {
        color: COLORS.WHITE,
        fontSize: 20,
        lineHeight: '100%',
        fontWeight: 700,
      },
      discountTextDiv: {
        color: 'white',
      },
      button: {
        padding: '6px 12px',
        backgroundColor: COLORS.SEO.TOO_LONG,
        color: '#220158',
      },
      timer: {},
    },
    dealName: 'Year End Sale!',
    icon: StarIconYellow,
    showIconOnLeft: true,
    showIconOnRight: true,
    discountText: `Subscribe to Yearly Plans with up to 60% off and get a bonus 50% on Credit Recharge above ₹10k!`,
    discountTextInternational: `🔥 ${discountPercent}% discount on all plans till 28th November, 2022. Use code: DUKAANBF50`,
    showButtonRedirect: !isMobile,
    buttonText: CtaButton,
    buttonRedirectLink: accountUrls.subscriptionPurchasePath,
    showTimer: true,
    showDealAfterTimerEnd: false,
    startDateInternational: '11/24/2022',
    endDateInternational: '11/26/2022',
    timerEndDateInternational: '11/26/2022',
    startDate: '12/11/2024',
    endDate: '12/31/2024',
    timerEndDate: '12/31/2024',
    subscriptionStripColor:
      'linear-gradient(90deg, #1B0050 3.31%, #490383 150.75%)',
  },
  {
    classes: {
      root: {
        background:
          'linear-gradient(90deg, rgba(229, 11, 32, 0.08) 0%, rgba(229, 11, 32, 0.12) 100%)',
        padding: isMobile ? '12px 24px' : '0 32px',
        height: isMobile ? 'auto' : '65px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: isMobile ? 'column' : 'row',
        position: 'relative',
      },
      gradientDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'fit-content',
      },
      icon: {
        marginRight: '8px',
        color: '#FF0000',
      },
      boldText: {
        background: '#FF0000',
        color: 'white',
        padding: '4px 12px',
        borderRadius: '4px',
        fontWeight: 800,
        fontSize: '15px',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        animation: 'pulse 2s infinite',
        margin: 0,
        whiteSpace: 'nowrap',
      },
      discountTextDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        marginTop: isMobile ? '12px' : 0,
        // paddingLeft: isMobile ? 0 : '24px',
        // paddingRight: isMobile ? 0 : '180px',
      },
      discountText: {
        color: '#000',
        fontSize: '15px',
        fontWeight: 600,
        margin: 0,
        textAlign: 'center',
        maxWidth: '700px',
      },
      button: {
        position: isMobile ? 'relative' : 'absolute',
        right: '32px',
        marginTop: isMobile ? '12px' : 0,
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        background: '#FFFFFF',
        padding: '8px 16px',
        borderRadius: '6px',
        cursor: 'pointer',
        transition: 'all 0.2s',
        overflow: 'hidden',
        border: '1px solid rgba(229, 11, 32, 0.15)',
        boxShadow: '0 2px 4px rgba(229, 11, 32, 0.1)',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          transform: 'translateY(-1px)',
          boxShadow: '0 4px 8px rgba(229, 11, 32, 0.15)',
          border: '1px solid rgba(229, 11, 32, 0.25)',
        },
        '& .logo-container': {
          display: isMobile ? 'none' : 'flex',
          alignItems: 'center',
          gap: '8px',
          paddingRight: '12px',
          borderRight: '1px solid rgba(229, 11, 32, 0.15)',
          '& img.logo': {
            height: '20px',
            width: 'auto',
          },
          '& img.razorpay-logo': {
            height: '16px',
          },
          '& .arrow': {
            color: 'rgb(229, 11, 32)',
          },
        },
        '& span': {
          fontWeight: 600,
          color: 'rgb(229, 11, 32)',
          letterSpacing: '0.5px',
          fontSize: '14px',
          marginLeft: '12px',
          whiteSpace: 'nowrap',
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: '-100%',
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(90deg, transparent, rgba(255,255,255,.5), transparent)',
          animation: 'shine 2s infinite',
        },
      },
      timer: {
        marginTop: isMobile ? '6px' : 0,
        // background: 'rgba(255, 0, 0, 0.15)',
        padding: '12px',
        borderRadius: '4px',
        '& .section-text-5': {
          fontSize: '16px',
          fontWeight: 600,
          color: 'rgb(229, 11, 32)',
        },
        '& .text-10': {
          fontSize: '12px',
          color: 'rgb(229, 11, 32)',
          opacity: 0.8,
        },
        '& .mx4': {
          margin: '0 4px',
        },
        '& .mr12': {
          marginRight: '12px',
        },
      },
    },
    dealName: 'URGENT!',
    icon: null,
    showIconOnLeft: false,
    showIconOnRight: false,
    discountText: `PhonePe will be discontinued soon. Please switch to Razorpay or Cashfree for online payments.`,
    discountTextInternational: `Phonepe will be discontinued soon, please switch to Razorpay or Cashfree.`,
    showButtonRedirect: false,
    buttonText: RazorpayCtaButton,
    buttonRedirectLink: accountUrls.paymentsSectionPath,
    showTimer: true,
    showDealAfterTimerEnd: false,
    startDate: '01/08/2025',
    endDate: '01/24/2025',
    timerEndDate: '01/24/2025',
    startDateInternational: '11/24/2022',
    endDateInternational: '11/26/2022',
    timerEndDateInternational: '11/26/2022',
    '@keyframes shine': {
      to: {
        left: '200%',
      },
    },
    '@keyframes pulse': {
      '0%': { transform: 'scale(1)' },
      '50%': { transform: 'scale(1.05)' },
      '100%': { transform: 'scale(1)' },
    },
  },
  {
    classes: {
      root: {
        background:
          'linear-gradient(90deg, rgba(245, 158, 11, 0.08) 0%, rgba(245, 158, 11, 0.12) 100%)',
        padding: isMobile ? '12px 24px' : '0 32px',
        height: isMobile ? 'auto' : '65px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: isMobile ? 'column' : 'row',
        position: 'relative',
      },
      gradientDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'fit-content',
      },
      icon: {
        marginRight: '8px',
        color: '#F59E0B',
      },
      boldText: {
        background: '#F59E0B',
        color: 'white',
        padding: '4px 12px',
        borderRadius: '4px',
        fontWeight: 800,
        fontSize: '15px',
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        animation: 'pulse 2s infinite',
        margin: 0,
        whiteSpace: 'nowrap',
      },
      discountTextDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        marginTop: isMobile ? '12px' : 0,
        paddingLeft: isMobile ? 0 : '24px',
        paddingRight: isMobile ? 0 : '24px',
      },
      discountText: {
        color: '#000',
        fontSize: '15px',
        fontWeight: 600,
        margin: 0,
        textAlign: 'center',
        maxWidth: '700px',
      },
      button: {
        position: isMobile ? 'relative' : 'absolute',
        right: '32px',
        marginTop: isMobile ? '12px' : 0,
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        background: '#FFFFFF',
        padding: '8px 16px',
        borderRadius: '6px',
        cursor: 'pointer',
        transition: 'all 0.2s',
        overflow: 'hidden',
        border: '1px solid rgba(245, 158, 11, 0.15)',
        boxShadow: '0 2px 4px rgba(245, 158, 11, 0.1)',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          transform: 'translateY(-1px)',
          boxShadow: '0 4px 8px rgba(245, 158, 11, 0.15)',
          border: '1px solid rgba(245, 158, 11, 0.25)',
        },
        '& .logo-container': {
          display: isMobile ? 'none' : 'flex',
          alignItems: 'center',
          gap: '8px',
          paddingRight: '12px',
          borderRight: '1px solid rgba(245, 158, 11, 0.15)',
          '& img.logo': {
            height: '20px',
            width: 'auto',
          },
          '& img.razorpay-logo': {
            height: '16px',
          },
          '& .arrow': {
            color: 'rgb(245, 158, 11)',
          },
        },
        '& span': {
          fontWeight: 600,
          color: 'rgb(245, 158, 11)',
          letterSpacing: '0.5px',
          fontSize: '14px',
          marginLeft: '12px',
          whiteSpace: 'nowrap',
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: '-100%',
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(90deg, transparent, rgba(255,255,255,.5), transparent)',
          animation: 'shine 2s infinite',
        },
      },
      timer: {
        marginTop: isMobile ? '6px' : 0,
        padding: '12px',
        borderRadius: '4px',
        '& .section-text-5': {
          fontSize: '16px',
          fontWeight: 600,
          color: 'rgb(245, 158, 11)',
        },
        '& .text-10': {
          fontSize: '12px',
          color: 'rgb(245, 158, 11)',
          opacity: 0.8,
        },
        '& .mx4': {
          margin: '0 4px',
        },
        '& .mr12': {
          marginRight: '12px',
        },
      },
    },
    dealName: 'ALERT!',
    icon: null,
    showIconOnLeft: false,
    showIconOnRight: false,
    discountText: `We're currently performing server maintenance to serve you better`,
    discountTextInternational: `We're currently performing server maintenance to serve you better`,
    showButtonRedirect: false,
    buttonText: RazorpayCtaButton,
    buttonRedirectLink: accountUrls.paymentsSectionPath,
    showTimer: false,
    showDealAfterTimerEnd: false,
    startDate: '12/31/2024',
    endDate: '01/01/2025',
    timerEndDate: '01/01/2025',
    startDateInternational: '12/31/2024',
    endDateInternational: '01/01/2025',
    timerEndDateInternational: '01/01/2025',
    '@keyframes shine': {
      to: {
        left: '200%',
      },
    },
    '@keyframes pulse': {
      '0%': { transform: 'scale(1)' },
      '50%': { transform: 'scale(1.05)' },
      '100%': { transform: 'scale(1)' },
    },
  },
  {
    classes: {
      root: {
        width: '100%',
        background: isMobile
          ? 'radial-gradient(circle closest-corner at 50% -210%, rgba(221,49,134,1) 62%, rgba(94,34,169,1) 100%)'
          : 'linear-gradient(65deg, rgba(255,153,51,1) 24%, rgba(255,255,255,1) 44%, rgba(187,221,184,1) 68%, rgba(19,136,8,1) 100%)',
        padding: isMobile ? '12px 32px' : '0px',
        justifyContent: 'center',
        position: 'relative',
      },
      gradientDiv: {
        padding: isMobile ? '' : '8px 32px',
        background: isMobile ? 'transparent' : 'transparent',
        height: isMobile ? '' : '100%',
        position: isMobile ? 'relative' : 'absolute',
        left: 0,
      },
      icon: {
        '& path': { fill: COLORS.WHITE },
      },
      boldText: {
        color: COLORS.WHITE,
        fontStyle: 'italic',
        fontSize: 18,
        lineHeight: '20px',
        fontWeight: 900,
      },
      discountTextDiv: {
        paddingRight: !isMobile ? 32 : 0,
      },
      button: {
        padding: '6px 12px',
        backgroundColor: COLORS.ORANGE_4,
        color: COLORS.BLACK_1,
      },
      timer: {
        padding: isMobile ? 0 : '8px 32px 8px 0px',
        ...(isMobile ? {} : { position: 'absolute', right: 0 }),
      },
    },
    dealName: 'Republic day sale',
    icon: StarIconYellow,
    showIconOnLeft: true,
    showIconOnRight: true,
    discountText: `Upgrade now to Yearly plan and avail upto 26% discount!`,
    discountTextInternational: `🔥 ${discountPercent}% discount on all plans.`,
    showButtonRedirect: !isMobile,
    buttonText: `Upgrade now`,
    buttonRedirectLink: accountUrls.subscriptionPurchasePath,
    showTimer: true,
    showDealAfterTimerEnd: true,
    startDateInternational: '02/24/2023',
    endDateInternational: '03/01/2023',
    timerEndDateInternational: '03/01/2023',
    startDate: '01/19/2024',
    endDate: '01/26/2024',
    timerEndDate: '01/26/2024',
    subscriptionStripColor: 'black',
  },
];
