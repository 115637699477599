import React from 'react';
import {
  CASHFREE_KEY,
  PAYPAL_KEY,
  PHONEPE_KEY,
  RAZORPAY_KEY,
  STRIPE_KEY,
} from '../../context/PaymentsProvider';
import { PAYPAL_SUPPORTED_CURRENCY_CODES } from '../../utils/currencies';
import PaypalSetupForm from '../InternationalPayments/PaypalSetupForm';
import StripeSetupForm from '../InternationalPayments/StripeSetupForm';
import {
  CODSquare,
  CashfreeSquare,
  PaypalSquare,
  PhonepeSquare,
  RazorpayLarge,
  StripeSquare,
} from '../../SvgIcon';
import { HELP_LINKS } from '../../constants';
import RazorpaySetupForm from '../InternationalPayments/RazorpaySetupForm';
import CODToggle from '../InternationalPayments/CODToggle';
import CashfreeSetupForm from '../InternationalPayments/CashfreeSetupForm';
import PhonepeSetupForm from '../InternationalPayments/PhonepeSetupForm';
import {
  GET_CASHFREE_ACCOUNT_STATUS,
  GET_CASHFREE_ONBOARDING_LINK,
} from '../../ApiUrls';
import CashfreeOtpSetupForm from '../InternationalPayments/CashfreeOtpSetupForm';

export const ON_HOLD = 0;
export const PAYOUTS = 1;
export const REFUNDS = 2;

export const TXN_DRAFT_STATUS = -1;
export const TXN_PENDING_STATUS = 0;
export const TXN_COMPLETED_STATUS = 1;
export const TXN_DISPUTED_STATUS = 2;
export const TXN_FAILED_STATUS = 3;
export const TXN_REFUNDED_STATUS = 4;
export const TXN_REFUND_INITIATED_STATUS = 5;
export const TXN_PAYOUT_INITIATED_STATUS = 6;

// on hold: show order status only
// payouts: processing 6, failed 3, successful 1
// refunded: processing 5, done 4

export const TXN_HOLD_TYPE = 0;
export const TXN_PAYOUT_TYPE = 1;
export const TXN_REFUNDED_TYPE = 2;

export const UPI_PAYMENT_MODE = 0;
export const BANK_PAYMENT_MODE = 1;

export const COD_PAYMENT_METHOD = 0;
export const ONLINE_PAYMENT_METHOD = 1;
export const BOTH_PAYMENT_METHOD = 2;

export const PAYMENT_METHODS = {
  [COD_PAYMENT_METHOD]: 'Cash on Delivery',
  [ONLINE_PAYMENT_METHOD]: 'Online Payments',
  [BOTH_PAYMENT_METHOD]: 'COD + Online',
};

export const PAYOUT_TOGGLE_OPTIONS = [
  {
    label: 'Pending payout',
    value: ON_HOLD,
  },
  {
    label: 'Completed payouts',
    value: PAYOUTS,
  },
  { label: 'Refunds', value: REFUNDS },
];

export const PAYOUTS_TABLE_COLUMNS = {
  [ON_HOLD]: [
    {
      title: 'Order ID',
      key: 'order.display_order_id',
    },
    {
      title: 'Order status',
      key: 'order.status',
    },
    {
      title: 'Order date',
      key: 'created_at',
      sortable: true,
      tooltipAscText: 'Sort by latest to oldest',
      tooltipDscText: 'Sort by oldest to latest',
    },
    {
      title: 'Order amount',
      key: 'order__total_cost',
      rightAlign: true,
      // sortable: true,
      // tooltipAscText: 'Sort by high to low',
      // tooltipDscText: 'Sort by low to high',
    },
  ],
  [PAYOUTS]: [
    {
      title: 'Payout date',
      key: 'created_at',
      // sortable: true,
      // tooltipAscText: 'Sort by latest to oldest',
      // tooltipDscText: 'Sort by oldest to latest',
    },
    // {
    //   title: 'Payout status',
    //   key: 'txn_status',
    // },
    {
      title: 'Transaction ID',
      key: 'utr',
    },
    {
      title: 'Total order amount',
      key: 'amount',
      // rightAlign: true,
      // sortable: true,
      // tooltipAscText: 'Sort by high to low',
      // tooltipDscText: 'Sort by low to high',
    },
    // {
    //   title: 'Transaction fees',
    //   key: 'payment_gateway_charges',
    //   rightAlign: true,
    // },
    {
      title: 'Total payout amount',
      key: 'payout_final_amount_paid',
      // rightAlign: true,
      // sortable: true,
      // tooltipAscText: 'Sort by high to low',
      // tooltipDscText: 'Sort by low to high',
    },
  ],
  [REFUNDS]: [
    {
      title: 'Order ID',
      key: 'order.display_order_id',
    },
    {
      title: 'Refund status',
      key: 'txn_status',
    },
    {
      title: 'Transaction ID',
      key: 'utr',
    },
    {
      title: 'Refund date',
      key: 'refund_created_at',
      // sortable: true,
      // tooltipAscText: 'Sort by latest to oldest',
      // tooltipDscText: 'Sort by oldest to latest',
    },
    {
      title: 'Order amount',
      key: 'amount',
      // rightAlign: true,
      // sortable: true,
      // tooltipAscText: 'Sort by high to low',
      // tooltipDscText: 'Sort by low to high',
    },
  ],
};

export const PAYOUT_ORDERING_OPTIONS = {
  [ON_HOLD]: 'created_at',
  [PAYOUTS]: 'created_at',
  [REFUNDS]: 'refund_created_at',
};

export const PAYOUT_SORT_OPTIONS = {
  [ON_HOLD]: [
    {
      key: 'created_at-desc',
      label: 'Order date (Newest first)',
    },
    {
      key: 'created_at-asc',
      label: 'Order date (Oldest first)',
    },
    {
      key: 'order__total_cost-desc',
      label: 'Order amount (High to low)',
    },
    {
      key: 'order__total_cost-asc',
      label: 'Order amount (Low to high)',
    },
  ],
  [PAYOUTS]: [
    {
      key: 'created_at-desc',
      label: 'Payout date (Newest first)',
    },
    {
      key: 'created_at-asc',
      label: 'Payout date (Oldest first)',
    },
    {
      key: 'amount-desc',
      label: 'Payout amount (High to low)',
    },
    {
      key: 'amount-asc',
      label: 'Payout amount (Low to high)',
    },
    {
      key: 'payout_final_amount_paid-desc',
      label: 'Total (High to low)',
    },
    {
      key: 'payout_final_amount_paid-asc',
      label: 'Total (Low to high)',
    },
  ],
  [REFUNDS]: [
    {
      key: 'refund_created_at-desc',
      label: 'Refund date (Newest first)',
    },
    {
      key: 'refund_created_at-asc',
      label: 'Refund date (Oldest first)',
    },
    {
      key: 'payout_final_amount_paid-desc',
      label: 'Order amount (High to low)',
    },
    {
      key: 'payout_final_amount_paid-asc',
      label: 'Order amount (Low to high)',
    },
  ],
};

export const PAYMENT_GATEWAY_IDS = {
  PADDLE: 1,
  RAZOR_PAY: 2,
  CASHFREE: 3,
  STRIPE: 4,
  PAYPAL: 5,
  SIMPL: 6,
  LAZYPAY: 7,
  SEZZLE: 8,
  BRAINTREE: 9,
  DLOCAL: 10,
  XENDIT: 11,
  TWO_C_TWO_P: 12,
  PAYMONGO: 13,
  BRAINTREE_NATIVE: 14,
  PHONEPE: 17,
  COD: 15,
  MANUAL: 16,
};

export const COD_ABBREVIATED_TITLE = 'COD';

export const TRANSACTION_COUNT_FROM_WALLET = {
  [TXN_HOLD_TYPE]: 'txn_on_hold',
  [TXN_PAYOUT_TYPE]: 'txn_completed',
  [TXN_REFUNDED_TYPE]: 'txn_refunded',
};

export const PAYMENT_MODES = {
  0: 'Dukaan Pay',
  1: 'Dukaan Pay',
  2: 'Razorpay',
};

export const TRANSACTION_OPTIONS = [
  {
    value: ON_HOLD,
    label: 'On hold',
    count: 0,
    helpText:
      'Ongoing & delivered orders for which payment is yet to be processed.',
  },
  {
    value: PAYOUTS,
    label: 'Payouts',
    count: 0,
    helpText: 'Orders for which payment has been credited into your account.',
  },
  {
    value: REFUNDS,
    label: 'Refunds',
    count: 0,
    helpText:
      'Cancelled & Failed orders for which payment is transferred back to the customer.',
  },
];

export const DUKAAN_DELIVERY_TRANSACTION_OPTIONS = [
  {
    value: ON_HOLD,
    label: 'On hold',
    count: 0,
    helpText:
      'Shipped & Delivered orders for which payment is yet to be processed.',
  },
  {
    value: PAYOUTS,
    label: 'Payouts',
    count: 0,
    helpText: 'Orders for which payment has been credited into your account.',
  },
];

export const TRANSACTION_STATUS_MAP = {
  [TXN_PENDING_STATUS]: 'pending',
  [TXN_REFUND_INITIATED_STATUS]: 'processing',
  [TXN_PAYOUT_INITIATED_STATUS]: 'processing',
  [TXN_FAILED_STATUS]: 'failed',
  [TXN_COMPLETED_STATUS]: 'successful',
  [TXN_REFUNDED_STATUS]: 'successful',
};

export const PAYMENT_CONTACT_SUPPORT_MESSAGE = (amount, id) =>
  `Hi, my payment of ${amount} with #${id} is on hold. Could you please assist on the same?`;

export const INVALID_POD_MESSAGE = (amount, id) =>
  `Hi, my proof of delivery of ${amount} with #${id} couldn't be verified. Could you please assist on the same?`;

export const PAYMENT_TAB_OPTIONS = [
  'paid_orders',
  'dukaan_delivery_cod_orders',
];

export const PAYMENT_TABS = {
  PAID_ORDERS: 0,
  COD_ORDERS: 1,
};

export const COD_PAYMENT_METHOD_TITLE = 'Cash on delivery';
export const PAYPAL_PAYMENT_METHOD_TITLE = 'Paypal';
export const STRIPE_PAYMENT_METHOD_TITLE = 'Stripe';

export const STRIPE_PAYMENT_SETUP = (stripeKey) => {
  const stripeObj = {
    provider: STRIPE_KEY,
    name: 'Stripe',
    title: 'Credit cards - powered by Stripe',
    Image: StripeSquare,
    FormComponent: StripeSetupForm,
    present: !!stripeKey,
    activated: !!stripeKey,
    canChange: true,
    canRemove: true,
    // helpLink: HELP_LINKS.SETUP_STRIPE,
    id: PAYMENT_GATEWAY_IDS.STRIPE,
  };
  return stripeObj;
};

export const PAYPAL_PAYMENT_SETUP = (paypalKey, currencyCode) => {
  const paypalObj = {
    provider: PAYPAL_KEY,
    name: 'Paypal',
    title: 'Paypal',
    FormComponent: PaypalSetupForm,
    present: !!paypalKey,
    activated: !!paypalKey,
    Image: PaypalSquare,
    hidden: !PAYPAL_SUPPORTED_CURRENCY_CODES.includes(currencyCode),
    helpLink: HELP_LINKS.SETUP_PAYPAL,
    canChange: true,
    canRemove: true,
    id: PAYMENT_GATEWAY_IDS.PAYPAL,
  };
  return paypalObj;
};

export const RAZORPAY_PAYMENT_SETUP = {
  provider: RAZORPAY_KEY,
  // canChange: true,
  // canRemove: true,
  name: 'Razorpay',
  title: 'Razorpay',
  canRemove: true,
  Image: RazorpayLarge,
  // FormComponent: RazorpaySetupForm,
  action: <RazorpaySetupForm />,
};

export const CASHFREE_PAYMENT_SETUP = {
  provider: CASHFREE_KEY,
  canChange: false,
  canRemove: true,
  showLinkButton: true,
  hasKycStatusFlow: true,
  name: 'Cashfree Payments',
  title: 'Cashfree Payments',
  Image: CashfreeSquare,
  getKycStatus: GET_CASHFREE_ACCOUNT_STATUS,
  getOnboardingLink: GET_CASHFREE_ONBOARDING_LINK,
  FormComponent: CashfreeSetupForm,
  OtpFormComponent: CashfreeOtpSetupForm,
};

export const PHONEPE_PAYMENT_SETUP = {
  provider: PHONEPE_KEY,
  canChange: true,
  canRemove: true,
  name: 'PhonePe Payment Gateway',
  title: 'PhonePe Payment Gateway',
  Image: PhonepeSquare,
  FormComponent: PhonepeSetupForm,
};

export const COD_PAYMENT_SETUP = {
  name: 'Cash on delivery',
  title: 'Cash on delivery',
  subtitle: 'Receive payments in cash upon delivery.',
  Image: CODSquare,
  action: <CODToggle />,
};

export const MAXIMUM_TRANSACTION_LIMIT_CHANGE_EMAIL = {
  SUBJECT: 'Requesting change of maximum transaction limit with Dukaan Pay',
  BODY: 'Request maximum transaction limit',
};

export const TRANSACTION_FEE_KEY_PLAN_STATS = 'online_transaction_fee';
export const WAREHOUSE_KEY_PLAN_STATS = 'warehouses_limit';

export const DUKAAN_PAY_LAST_DATE = '5 November, 2023';
