import { useEffect, useState } from 'react';

import qs from 'query-string';
import { deleteRequest, getRequest, postRequest } from '../utils/http';
import {
  POST_CORE_PLUGIN,
  GET_CORE_PLUGIN_CONFIG,
  DELETE_CORE_PLUGIN,
  DEACTIVATE_CORE_PLUGIN,
} from '../ApiUrls';
import { getErrorMessage, noop } from '../utils';
import useCustomSnackbar from './useCustomSnackbar';
import { useSocket } from '../context/SocketProvider';
import {
  COD_DISABLE_PRODUCTS_CORE_PLUGIN_ID,
  PINCODE_CHECKER_CORE_PLUGIN_ID,
  CONNECT_SHIPPING_ACCOUNT_ID,
  SHIPROCKET_CORE_PLUGIN_ID,
  SMTP_PLUGIN_ID,
} from '../Account/Sections/constants';

const PLUGIN_REMOVED_MESSAGES = {
  [SMTP_PLUGIN_ID]: 'SMTP email removed successfully',
  [SHIPROCKET_CORE_PLUGIN_ID]: 'Shiprocket disconnected successfully!',
  [CONNECT_SHIPPING_ACCOUNT_ID]:
    'Connected shipping accounts deactivated successfully!',
  [COD_DISABLE_PRODUCTS_CORE_PLUGIN_ID]: 'Feature deactivated successfully!',
  [PINCODE_CHECKER_CORE_PLUGIN_ID]: 'Feature deactivated successfully!',
};

const useCorePlugin = (pluginId, successCallback = noop) => {
  const { enqueueSnackbar } = useCustomSnackbar();
  const { on } = useSocket();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [corePluginDetails, setCorePluginDetails] = useState({});
  const [isEnabled, setIsEnabled] = useState(false);
  const [roomIdAndCb, setRoomIdAndCb] = useState({ roomId: null, cb: noop });

  const fetchCorePluginDetails = (redact = true) => {
    setIsLoading(true);
    getRequest({
      url: `${GET_CORE_PLUGIN_CONFIG(pluginId)}${
        redact ? '' : '?redact=false'
      }`,
    })
      .then((res) => {
        const { config_data: configData } = res?.data || {};
        setCorePluginDetails(configData);
        setIsEnabled(configData?.is_active ?? false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  const deactivateCorePluginDetails = (callBackFn = noop) => {
    setIsSubmitting(true);
    postRequest({ url: DEACTIVATE_CORE_PLUGIN(pluginId) })
      .then(() => {
        enqueueSnackbar(PLUGIN_REMOVED_MESSAGES[pluginId]);
        setCorePluginDetails({});
      })
      .catch((err) => {
        enqueueSnackbar('Something went wrong!', 'error');
        console.log(err);
      })
      .finally(() => {
        setIsSubmitting(false);
        callBackFn();
      });
  };

  const deleteCorePluginDetails = async (payload, callBackFn = noop) => {
    setIsSubmitting(true);
    return deleteRequest({ url: DELETE_CORE_PLUGIN(pluginId), data: payload })
      .then(() => {
        enqueueSnackbar(PLUGIN_REMOVED_MESSAGES[pluginId]);
        setCorePluginDetails({});
        setIsEnabled(false);
      })
      .catch((err) => {
        enqueueSnackbar('Something went wrong!', 'error');
        console.log(err);
      })
      .finally(() => {
        setIsSubmitting(false);
        callBackFn();
      });
  };

  const postCorePluginDetails = (
    payload,
    callBackFn = noop,
    params = {},
    requestSentCallback = noop
  ) => {
    setIsSubmitting(true);
    let url = `${POST_CORE_PLUGIN(pluginId)}`;
    if (Object.keys(params).length) {
      url += `?${qs.stringify({ ...params })}`;
    }
    postRequest({
      url,
      data: payload,
      doClean: false,
    })
      .then((res) => {
        const { task_id: taskId } = res.data;
        setRoomIdAndCb({ roomId: taskId, cb: callBackFn });
        requestSentCallback(res?.data ?? {});
      })
      .catch((err) => {
        const { errors = {} } = err.data?.data || {};
        const errMsg = getErrorMessage(errors);
        enqueueSnackbar(errMsg, 'error');
        callBackFn();
        setIsSubmitting(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (roomIdAndCb.roomId) {
      on(roomIdAndCb.roomId, (res) => {
        const { data = {} } = res ?? {};
        if (data.status) {
          successCallback();
          fetchCorePluginDetails();
        } else {
          const { config_data: configData = {} } = data;
          const errMsg = getErrorMessage(configData);
          enqueueSnackbar(errMsg, 'error');
        }
        setIsSubmitting(false);
        roomIdAndCb.cb();
      });
    }
  }, [roomIdAndCb?.roomId]);

  return {
    isSubmitting,
    isLoading,
    corePluginDetails,
    setIsSubmitting,
    isEnabled,
    fetchCorePluginDetails,
    deleteCorePluginDetails,
    postCorePluginDetails,
    deactivateCorePluginDetails,
  };
};

export default useCorePlugin;
