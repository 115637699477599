import React from 'react';
import { noop } from '../../utils';
import {
  NUDGE_ICON_VARIANTS,
  NUDGE_SIZES,
  NUDGE_TYPES,
  NUDGE_VARIANTS,
} from '../constants';
import { CloseIcon } from '../../SvgIcon';
import { LightTooltip } from '../../shared/View/LightTooltip';

const Nudge = ({
  text = '',
  size = NUDGE_SIZES.DEFAULT,
  variant = NUDGE_VARIANTS.WARNING,
  type = NUDGE_TYPES.PRIMARY,
  iconVariant = NUDGE_ICON_VARIANTS.ORANGE,
  iconSize = { width: 18, height: 18 },
  ctaLabel = '',
  onClick = noop,
  className = '',
  onClose,
  tooltipContent = '',
}) => {
  const Icon = iconVariant;

  return (
    <div
      className={`nudge nudge-size-${size} nudge-type-${type} nudge-variant-${variant} ${className}`}
    >
      <div className="info-icon">
        {tooltipContent ? (
          <LightTooltip title={tooltipContent} placement="top">
            <Icon width={iconSize.width} height={iconSize.height} />
          </LightTooltip>
        ) : (
          <Icon width={iconSize.width} height={iconSize.height} />
        )}
      </div>
      <div className="text-content">
        {text}
        {ctaLabel && (
          <span className="cta-label ml12" onClick={onClick}>
            {ctaLabel}
          </span>
        )}
        {typeof onClose === 'function' && (
          <CloseIcon className="close-icon" onClick={onClose} />
        )}
      </div>
    </div>
  );
};

export default Nudge;
