import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { FormInput } from '../../shared/Form';
import Validation from '../../shared/Form/Validation';
import { postRequest } from '../../utils/http';
import { ExternalLink } from '../../shared';
import { noop } from '../../utils';
import { useAppContext } from '../../context/AppContext';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import SpinnerButton from '../../shared/SpinnerButton';
import Nudge from '../../Subscription/components/Nudge';
import {
  NUDGE_ICON_VARIANTS,
  NUDGE_TYPES,
  NUDGE_VARIANTS,
} from '../../Subscription/constants';
import { CREATE_CASHFREE_MERCHANT_ACCOUNT } from '../../ApiUrls';
import { usePayments } from '../../context/PaymentsProvider';

const CASHFREE_CREATE_ACCOUNT_URL =
  'https://merchant.cashfree.com/merchants/signup';

const CashfreeSetupForm = ({
  isActivated = false,
  isPresent = false,
  successCallback = noop,
}) => {
  const { business, fetchBusinessDetails } = useAppContext();
  const { fetchPaymentGatewayCreds, paymentProviderData } = usePayments();
  const { enqueueSnackbar } = useCustomSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialCreds, setInitialCreds] = useState({
    merchant_name: '',
    merchant_email: '',
    poc_phone: '',
  });

  const onSubmit = (values) => {
    setSubmitting(true);
    postRequest({
      url: CREATE_CASHFREE_MERCHANT_ACCOUNT(business.uuid),
      data: values,
    })
      .then(() => {
        enqueueSnackbar('Cashfree Merchant account set up successfully!');
        successCallback();
        fetchBusinessDetails();
      })
      .catch(() => {
        enqueueSnackbar(
          'We are unable to verify your details. Please enter correct values.',
          'error'
        );
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (isPresent) {
      setLoading(true);
      fetchPaymentGatewayCreds(
        paymentProviderData?.uuid,
        (data) => {
          const creds = data?.data?.payment_provider_account;
          if (creds) {
            setInitialCreds({
              client_id: creds?.client_id,
              client_secret: creds?.client_secret,
            });
          }
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...initialCreds }}
      render={({ handleSubmit, invalid }) => (
        <form className="mt4">
          {/* {!isPresent && (
            <Nudge
              variant={NUDGE_VARIANTS.PRIMARY}
              type={NUDGE_TYPES.PRIMARY}
              iconVariant={NUDGE_ICON_VARIANTS.BLUE}
              text={
                <p className="c-black-3 text-8 d-flex align-center">
                  Already have an Cashfree account?{' '}
                  <ExternalLink
                    href={CASHFREE_CREATE_ACCOUNT_URL}
                    className="text-medium c-black-3-i ml6 underline"
                  >
                    Link now
                  </ExternalLink>
                </p>
              }
            />
          )} */}

          <FormInput
            labelText="Business Name"
            name="merchant_name"
            placeholder="Enter Business Name"
            className="my24"
            required
            showRequired
            validate={Validation.required()}
          />
          <FormInput
            labelText="Business Email"
            name="merchant_email"
            placeholder="Enter Business Email"
            className="mb24"
            required
            showRequired
            validate={Validation.required()}
          />
          <FormInput
            labelText="Business Phone Number"
            name="poc_phone"
            placeholder="Enter Business Phone Number"
            className="mb24"
            required
            showRequired
            validate={Validation.required()}
          />
          <div className="text-center mt24">
            <SpinnerButton
              isLoading={submitting || loading}
              onClick={handleSubmit}
              disabled={invalid}
            >
              {isActivated && isPresent ? 'Update' : 'Create'}
            </SpinnerButton>
          </div>
        </form>
      )}
    />
  );
};

export default CashfreeSetupForm;
