import React, { useState, useRef } from 'react';
import { Form } from 'react-final-form';
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';

import { FormGroup, FormInput, FormNumberInput } from '../../shared/Form';
import { getRequest, postRequest } from '../../utils/http';
import { noop } from '../../utils';
import { useAppContext } from '../../context/AppContext';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import SpinnerButton from '../../shared/SpinnerButton';
import {
  SAVE_PAYMENT_CREDENTIALS_OTP,
  SAVE_PAYMENT_GATEWAY_CREDS,
} from '../../ApiUrls';
import { PAYMENT_GATEWAY_IDS } from '../Payments/constants';
import { parseSellerUsername } from '../../utils/string';
import useScript from '../../hooks/useScript';
import { RECAPTCHA_SITE_KEY } from '../../constants';
import './InputForm.scss';

const OTP_INTERVAL = 30000;

const CashfreeOtpSetupForm = ({ successCallback = noop }) => {
  const { business, fetchBusinessDetails } = useAppContext();
  const { enqueueSnackbar } = useCustomSnackbar();
  const [submitting, setSubmitting] = useState(false);
  const [OTPSent, setOTPSent] = useState(false);
  const otpRef = useRef(null);

  const [timerDate, setTimerDate] = useState(Date.now() + OTP_INTERVAL);

  const { isPhoneNumber, countryCode, mobileNumber, email } =
    parseSellerUsername(business?.role_username);

  useScript(
    'https://www.google.com/recaptcha/api.js?render=6Le1AaQjAAAAAG7VprkQcNmuuQsqDM93Yu_v7yNk'
  );

  const handleSendOTP = () => {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
        .then((token) =>
          getRequest({
            url: SAVE_PAYMENT_CREDENTIALS_OTP,
            data: { hashcode: token },
          }).then((resp) => {
            if (resp.status === 'success') {
              setOTPSent(true);
              setTimerDate(Date.now() + OTP_INTERVAL);
            }
          })
        );
    });
  };

  const onResendOtpLink = () => {
    setTimerDate(Date.now() + OTP_INTERVAL);
    handleSendOTP();
  };

  const onSubmit = (values) => {
    setSubmitting(true);
    postRequest({
      url: SAVE_PAYMENT_GATEWAY_CREDS(
        business.uuid,
        PAYMENT_GATEWAY_IDS.CASHFREE
      ),
      data: {
        otp: values.otp,
        reason: 'save_payment_credentials',
      },
    })
      .then(() => {
        enqueueSnackbar('Cashfree payments set up successfully!');
        successCallback();
        fetchBusinessDetails();
      })
      .catch((err) => {
        console.log(err);

        if (err?.data?.data?.otp?.length > 0) {
          enqueueSnackbar(err?.data?.data?.otp?.[0], 'error');
        } else if (err?.data?.data?.error) {
          enqueueSnackbar(err?.data?.data?.error, 'error');
        } else {
          enqueueSnackbar(
            'We are unable to verify your otp. Please enter correct value.',
            'error'
          );
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ otp: '', reason: 'save_payment_credentials' }}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({
        form: {
          mutators: { setValue },
        },
        handleSubmit,
        invalid,
        values,
      }) => (
        <form className="mt4">
          <div className="mt20">
            {!OTPSent && (
              <div className="delete-verify-account">
                <div className="full-w">
                  {isPhoneNumber ? (
                    <FormNumberInput
                      name="phone"
                      labelText="VERIFY YOUR MOBILE NUMBER"
                      placeholder="0123456789"
                      required
                      prependText={countryCode}
                      largePrependText
                      disabled
                      initialValue={mobileNumber}
                    />
                  ) : (
                    <FormInput
                      name="email"
                      value={email}
                      labelText={
                        <p className="field-label-text">
                          VERIFY YOUR EMAIL ADDRESS
                        </p>
                      }
                      required
                      disabled
                    />
                  )}
                </div>
                <SpinnerButton
                  type="button"
                  className="btn-outline-primary otp-button mt6"
                  onClick={handleSendOTP}
                >
                  Get OTP
                </SpinnerButton>
              </div>
            )}

            {OTPSent && (
              <div className="otp-verfication-form">
                <div className="otp-form-content">
                  <FormGroup
                    labelText="ENTER THE OTP"
                    className="otp-input-wrap mt24 mb24"
                  >
                    <OtpInput
                      ref={otpRef}
                      value={values.otp}
                      onChange={(value) => {
                        setValue('otp', value);
                      }}
                      numInputs={6}
                      isInputNum
                      shouldAutoFocus
                      className="mr10"
                    />
                  </FormGroup>
                </div>

                <Countdown
                  key={timerDate}
                  date={timerDate}
                  renderer={({ seconds, completed }) => {
                    if (completed) {
                      return (
                        <div className="resend-text-container">
                          <span
                            className="resend-otp-link anchor-1 resend-text-container"
                            onClick={onResendOtpLink}
                          >
                            Resend Code
                          </span>
                        </div>
                      );
                    }
                    return (
                      <div className="resend-text-container">
                        <span className="text-info resend-text-container">
                          Resend code in
                        </span>{' '}
                        <span className="resend-otp-timer resend-text-container">
                          {seconds} seconds
                        </span>
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </div>
          <div className="text-center mt24">
            <SpinnerButton
              isLoading={submitting}
              onClick={handleSubmit}
              disabled={invalid || !values.otp}
            >
              Verify
            </SpinnerButton>
          </div>
        </form>
      )}
    />
  );
};

export default CashfreeOtpSetupForm;
